import React from 'react'
//import { Link } from 'gatsby'
import { useTheme } from '../Theming'
import ThemeToggler from './ThemeToggler'

/*
const s = {
  display: 'none'
}
*/

export default () => {
  const theme = useTheme()
  return (
    <React.Fragment>
      <ThemeToggler
        css={{}}
        toggleTheme={theme.toggleTheme}
        themeName={theme.themeName}
      />
    </React.Fragment>
  )
}
/*
      <Link to="#" activeClassName="active" aria-label="View blog page" style={s}>
        Blog
      </Link>
      <Link to="#" activeClassName="active" aria-label="View blog page" style={s}>
        About
      </Link>
      <Link to="#" activeClassName="active" aria-label="View blog page" style={s}>
        Contact
      </Link>
 */
