import React from 'react'
import './wavyLine.css'

const WavyLine = () => {
  return (
    <div className="wavy" />
  )
}

export default WavyLine
