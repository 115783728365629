import React, {memo} from 'react'
import {css} from '@emotion/core'
import theme from 'prism-react-renderer/themes/nightOwl'
import {bpDesktopOnly} from '../../lib/breakpoints'
import Highlight, {defaultProps} from 'prism-react-renderer'
import WavyLine from '../WavyLine'

const wrapperStyles = css`
  overflow: auto;
  margin-left: -20px;
  margin-right: -20px;
  ${bpDesktopOnly} {
    margin-left: -80px;
    margin-right: -80px;
  }
`

const preStyles = css`
  float: left;
  min-width: 100%;
`

function renderCodeIndex(tokensLength, codeLineNumber) {
  if (tokensLength === 1) {
    return <span
      css={css`
        display: inline-block;
        width: 2em
      `}
    />
  } else {
    return <span
      css={css`
        display: inline-block;
        width: 2em;
        user-select: none;
        opacity: 0.3;
      `}
    >
      {codeLineNumber + 1}
    </span>
  }
}

function Code({children, codeString, className = 'language-js', searchMode = false, ...props}) {
  const language = className.replace(/language-/, '');
  let codeLine = false;

  return (
    <Highlight
      {...defaultProps}
      code={codeString}
      language={language}
      theme={theme}
    >
      {({className, style, tokens, getLineProps, getTokenProps}) => (
        <div css={!searchMode && wrapperStyles}>
          <pre className={className} style={style} css={preStyles}>
            {tokens.map((line, i) => {

              if (!searchMode || line.some(token => token.content !== '')) {
                codeLine = true;

                return (
                  <div
                    key={i}
                    {...getLineProps({
                      line,
                      key: i
                    })}
                  >
                    {
                      renderCodeIndex(tokens.length, i)
                    }
                    {line.map((token, key) => (
                      <span key={key} {...getTokenProps({ token, key })} />
                    ))}
                  </div>
                )
              } else {
                if (
                  codeLine &&
                  i < tokens.length - 1 // HACK for some reason, an additional blank line is added in "searchMode"???
                ) {
                  codeLine = false;
                  return <WavyLine key={i} />
                } else {
                  return null // Don't render line
                }
              }
            })}
          </pre>
        </div>
      )}
    </Highlight>
  )
}

export default memo(Code)
