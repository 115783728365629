import { createTheming } from '@callstack/react-theme-provider'
import colors from '../lib/colors'

const themes = {
  default: {
    themeName: 'default',
    colors: {
      primary: colors.blue,
      text: colors.black,
      bodyBg: colors.gray,
      bodyBgAlt: colors.white,
      headerBg: colors.blue,
      footerBg: colors.blue,
      link: colors.blue,
      postLink: colors.purple,
      title: colors.green,
      ...colors,
    },
  },
  dark: {
    themeName: 'dark',
    colors: {
      primary: colors.blueL2,
      text: colors.white,
      bodyBg: colors.black,
      bodyBgAlt: colors.shadow,
      headerBg: colors.black,
      footerBg: colors.black,
      link: colors.blueL3,
      postLink: colors.purpleL1,
      title: colors.greenL3,
      ...colors,
    },
  },
}

const { ThemeProvider, withTheme, useTheme } = createTheming(themes.default)

export { ThemeProvider, withTheme, useTheme, themes, colors }
