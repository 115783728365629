const colors = {
  black: '#131415',// Or #21252a
  white: '#fff',
  gray: '#fafafa',
  shadow: '#202020', /* Used primarily  by the "card" component in dark mode */
  red: '#E74C3C',
  brown: '#68422d',

  /* Main 3 colour theme: blue, green, purple */
  // Blues
  blueD2: '#092739',
  blueD1: '#1A3E53',
  blue: '#2d5268',
  blueL1: '#46697E',
  blueL2: '#6D899A',
  blueL3: '#91b8d0',

  // Greens
  greenD2: '#073C1B',
  greenD1: '#195730',
  green: '#2d6843',
  greenL1: '#45845C',
  greenL2: '#6DA281',
  greenL3: '#91d0a9',

  // Purples
  purple: '#382d68',
  purpleL1: '#8b91d0',
}

export default colors
